





































































































































































































































import { required, email, minChars, maxChars, lowercase, uppercase, number, specialChar } from '@/calendesk/forms/validators'
import CInfoAlert from '@/components/CInfoAlert.vue'
import { mapActions } from 'vuex'
import mixins from 'vue-typed-mixins'
import DialogMixins from '@/components/dialogs/mixins/DialogMixins'
import PaymentActions from '@/views/components/mixins/PaymentActions'
import CompanyDataActions from '@/calendesk/sections/section/shared/mixins/CompanyDataActions'
import CCountryAutocomplete from '@/components/CCountryAutocomplete.vue'
import { errorNotification } from '@/calendesk/prototypes/notifications'
import CPhoneInput from '@/views/components/CPhoneInput.vue'

export default mixins(CompanyDataActions, DialogMixins, PaymentActions).extend({
  name: 'SignUpDialogForm',
  components: { CPhoneInput, CCountryAutocomplete, CInfoAlert },
  data: () => ({
    loading: false,
    error: null,
    showPassword: false,
    form: {
      name: null,
      surname: null,
      default_phone: null,
      email: null,
      password: null
    },
    rules: {
      required,
      email,
      minChars,
      maxChars,
      lowercase,
      uppercase,
      number,
      specialChar
    }
  }),
  created () {
    this.companyVatIsoPrefix = (this.appConfiguration.companyCountryIsoCode || this.appConfiguration.language).toUpperCase()
    this.companyCountryIsoCode = (this.appConfiguration.companyCountryIsoCode || this.appConfiguration.language).toLowerCase()
  },
  methods: {
    ...mapActions({
      signUpUserAndStoreSession: 'user/signUpUserAndStoreSession'
    }),
    handleRedirect (pageUuid: string | null) {
      if (pageUuid) {
        this.closeDialog2()
        this.redirectToPageUuid(pageUuid)
      }
    },
    handleSignUp (): void {
      this.error = null

      if (!(this.$refs.SignupDialogForm as any).validate()) {
        errorNotification('form_is_invalid', null, false)
        return
      }

      this.loading = true
      this.$emit('loading', this.loading)

      const payload: Record<string, any> = {
        email: this.form.email,
        password: this.form.password,
        name: this.form.name,
        surname: this.form.surname
      }

      if (this.form.default_phone) {
        payload.default_phone = this.form.default_phone
      }

      if (this.showBillingData) {
        payload.default_address = {
          name: this.companyName,
          street: this.companyStreet,
          postal_code: this.companyPostalCode,
          city: this.companyCity,
          country_iso_code: this.companyCountryIsoCode,
          vat_iso_prefix: this.companyVatIsoPrefix,
          tax_number: this.companyTaxNumber
        }
      }

      this.signUpUserAndStoreSession(payload)
        .then(() => {
          this.loading = false
          this.$emit('loading', this.loading)
          this.closeDialog2()
        })
        .catch(error => {
          this.loading = false
          this.$emit('loading', this.loading)

          if ((error.response.data && error.response.data.errors && error.response.data.errors.email)) {
            this.error = this.$trans('email_is_taken')
            errorNotification('email_is_taken', error, false)
          } else {
            errorNotification('email_is_taken', error, false)
            this.error = this.$trans('error_occurred')
          }
        })
    }
  }
})
